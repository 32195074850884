<template>
  <svg
    width="13"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.85 1L1.01776 4.98154L1 5M1 5L4.85 9M1 5H13" stroke="#5E5AFF" />
  </svg>
</template>
